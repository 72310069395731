import { resolutionIsRefund } from './getPostReturnPayload';
import { ReviewMachineContext } from './type';

import { ItemResolution, Resolution } from '../../constant';
import { PostReturnPreviewInput, RefundDestinationType } from '../../types';

const getRefundDestination = (
  resolution?: Resolution,
  refundDestination?: RefundDestinationType | null,
) => {
  if (refundDestination) {
    return refundDestination;
  } else if (resolutionIsRefund(resolution)) {
    return resolution;
  }
  return undefined;
};

export const getPostReviewPayload = (
  context: ReviewMachineContext,
): PostReturnPreviewInput['input'] => {
  return {
    token: context?.token,
    payload: {
      order: {
        id: context.orderId,
      },
      refund_destination: getRefundDestination(context.resolution, context.refundDestination),
      return_items:
        context.selectedItems?.map((item) => ({
          item_id: item.itemId,
          quantity: item.quantity,
          resolution:
            context.resolution === Resolution.ReplaceTheSameItem
              ? ItemResolution.Exchange
              : ItemResolution.Refund,
          ...(item.exchangeVariant
            ? {
                replacement: {
                  external_product_id: item.exchangeVariant?.productId,
                  external_variant_id: item.exchangeVariant?.variantId,
                },
              }
            : {}),
          reason: item.selectedReason,
          subreason: item.selectedSubReason,
        })) ?? [],
      return_method: {
        id: context?.returnMethod?.id!,
      },
      ...(context?.exchangeItems?.length
        ? {
            exchange_items: context.exchangeItems?.map((item) => ({
              external_product_id: item.productId,
              external_variant_id: item.variantId,
              quantity: item.quantity,
            })),
          }
        : {}),
      gift_return: {
        is_gift_return: !!context?.isGiftReturn,
        recipient_email: context?.giftReturnShippingAddress?.email ?? null,
        recipient_first_name: context?.giftReturnShippingAddress?.first_name ?? null,
        recipient_last_name: context?.giftReturnShippingAddress?.last_name ?? null,
        recipient_shipping_address: context?.giftReturnRecipientAddress ?? null,
      },
    },
  };
};
