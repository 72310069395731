import { useTranslation } from 'react-i18next';

import { Box, Card, CardHeader, Flex, Radio, RadioGroup, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { RefundDestinationType, Resolution } from 'returns-logics';

import { extraBonusStyle } from '@/features/resolution/components/ResolutionList/index.css';
import useDevice from '@/hooks/useDevice';
import { useTranslateResolutionInfo } from '@/hooks/useResolutionInfo';
import { toCurrency } from '@/utils/price';

import { useReturnPreviewFlow } from '../../hooks/useReturnPreviewFlow';

export const resolutionIsRefund = (resolution?: unknown): resolution is RefundDestinationType => {
  return !!(
    resolution &&
    (resolution === Resolution.OriginalPayment ||
      resolution === Resolution.StoreCredit ||
      resolution === Resolution.Refundid)
  );
};

type ResolutionItemData = {
  radioValue: Resolution;
  resolutionName: string;
  description: string;
  active: boolean;
  renderChildren?: () => React.ReactNode;
};
type ResolutionRadioMapping = Partial<Record<Resolution, ResolutionItemData>>;

const RefundMethod = ({ showRefundMethod }: { showRefundMethod: boolean }) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const { returnPreviewFlowContext, returnPreviewFlowDispatch } = useReturnPreviewFlow();

  const translatedResolutionInfo = useTranslateResolutionInfo();

  const originalActive = returnPreviewFlowContext?.appliedResolutions?.includes(
    Resolution.OriginalPayment,
  );
  const storeActive = returnPreviewFlowContext?.appliedResolutions?.includes(
    Resolution.StoreCredit,
  );

  const resolutionRadioMapping: ResolutionRadioMapping = {
    [Resolution.StoreCredit]: {
      radioValue: Resolution.StoreCredit,
      resolutionName: translatedResolutionInfo?.[Resolution.StoreCredit]?.name,
      description: translatedResolutionInfo?.[Resolution.StoreCredit].description,
      active: !!storeActive,
      renderChildren: () => {
        const storeCreditIncentive =
          returnPreviewFlowContext?.preview?.summary?.store_credit_incentive_set?.presentment_money;

        if (!storeCreditIncentive?.amount || !storeCreditIncentive?.currency) return null;

        const price = toCurrency(
          storeCreditIncentive?.amount ?? '',
          storeCreditIncentive?.currency,
        );

        return (
          <Box className={extraBonusStyle}>
            <Text variant='body2' color={ColorVars['Orange']['Orange 1200']}>
              +
              {t('common.bonus', {
                price,
              })}
            </Text>
          </Box>
        );
      },
    },
    [Resolution.OriginalPayment]: {
      radioValue: Resolution.OriginalPayment,
      resolutionName: translatedResolutionInfo[Resolution.OriginalPayment].name,
      description: translatedResolutionInfo[Resolution.OriginalPayment].description,
      active: !!originalActive,
    },
    // 下架掉 Refundid
    // [Resolution.Refundid]: {
    //   radioValue: Resolution.Refundid,
    //   resolutionName: translatedResolutionInfo[Resolution.Refundid].name,
    //   description: translatedResolutionInfo[Resolution.Refundid].description,
    //   active: instantRefundWithRefundidActive,
    // },
  };

  const activeResolutions = Object.values(resolutionRadioMapping).filter(({ active }) => active);
  const activeRefundResolutionsEqual2 = activeResolutions.length === 2;

  const refundDestination = returnPreviewFlowContext?.refundDestination;

  return (
    showRefundMethod &&
    activeRefundResolutionsEqual2 && (
      <Box paddingX={mobile ? SpacingVars['6'] : 0}>
        <Card padding={SpacingVars['4']}>
          <CardHeader>
            <Text variant='subtitle1'>{t('REFUND_078426')}</Text>
          </CardHeader>

          <RadioGroup
            value={refundDestination}
            onChange={(value) => {
              if (resolutionIsRefund(value))
                returnPreviewFlowDispatch?.({
                  type: 'UPDATE_REFUND_DESTINATION',
                  data: {
                    refundDestination: value,
                  },
                });
            }}
          >
            <Flex direction='column' gap={mobile ? SpacingVars['3'] : SpacingVars['4']}>
              {activeResolutions?.map((resolution) => (
                <Radio key={resolution.radioValue} value={resolution.radioValue}>
                  <Flex direction='column'>
                    <Text variant='subtitle1' color={ColorVars['Grey']['Grey1200']}>
                      {resolution.resolutionName}
                    </Text>
                    {resolution.renderChildren?.()}
                    <Text variant='body2' color={ColorVars['Grey']['Grey 1100']}>
                      {resolution.description}
                    </Text>
                  </Flex>
                </Radio>
              ))}
            </Flex>
          </RadioGroup>
        </Card>
      </Box>
    )
  );
};

export default RefundMethod;
