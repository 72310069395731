import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';
import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

import SectionLayout, { SectionCommonProps, SummarySectionName } from './SectionLayout';

import { useSummaryContext } from '../SummaryProvider';

const ReturnCreditsSection = ({
  isFold,
  showDivider,
}: { isExchangeForAnything: boolean; isFold: boolean } & SectionCommonProps) => {
  const { previewSummary: returnPreviewSummary, returnItemsLength } = useSummaryContext();
  const isMobile = useDevice().mobile;

  const { t } = useTranslation();
  const itemsTaxTotal = returnPreviewSummary?.return_item_tax_total_set?.presentment_money;
  const itemsTotal = returnPreviewSummary?.return_item_subtotal_set?.presentment_money;
  const taxIncluded = returnPreviewSummary?.return_item_taxes_included;

  const extraCreditUsedSetPresentmentMoney =
    returnPreviewSummary?.extra_credit_used_set?.presentment_money;
  const preDiscountCreditUsedSetPresentmentMoney =
    returnPreviewSummary?.pre_discount_credit_used_set?.presentment_money;

  // 使用的折扣
  const creditUsedAmount =
    Number(extraCreditUsedSetPresentmentMoney?.amount ?? 0) +
    Number(preDiscountCreditUsedSetPresentmentMoney?.amount ?? 0);

  const creditAmount = {
    amount: creditUsedAmount,
    currency:
      extraCreditUsedSetPresentmentMoney?.currency ??
      preDiscountCreditUsedSetPresentmentMoney?.currency,
  };

  // 只需要判断 creditUsedAmount 是否大于 0 即可
  const showExtraCredit = creditUsedAmount > 0;
  const showTaxLabel = !!itemsTaxTotal && taxIncluded;
  const showItemsTax = !!itemsTaxTotal && !taxIncluded;

  // returns item 的价格和 returns item tax 价格总和
  const totalAmount = Number(itemsTotal?.amount ?? 0) + Number(itemsTaxTotal?.amount ?? 0);
  const returnsValue =
    Number(itemsTotal?.amount ?? 0) > 0
      ? `(${toCurrency(itemsTotal?.amount ?? 0, itemsTotal?.currency)})`
      : '-';

  const returnsTaxValue = toCurrency(itemsTaxTotal?.amount ?? 0, itemsTaxTotal?.currency);

  // section 栏的展示价格
  const sectionAmount = showItemsTax ? totalAmount : Number(itemsTotal?.amount ?? 0);
  const sectionValue = sectionAmount ? `(${toCurrency(sectionAmount, itemsTotal?.currency)})` : '-';

  const returnItemQty = returnItemsLength ? returnItemsLength : '';
  const returnItemLabel = `${Number(returnItemQty) > 1 ? t('v2.page.general.returnItems') : t('v2.page.general.returnItem')}${
    showTaxLabel ? ` (${t('page.general.taxIncluded')})` : ''
  }`;

  return (
    <Flex direction='column' gap={SpacingVars['1']}>
      <SectionLayout name={SummarySectionName.ReturnCredit}>
        <Text variant='body1'>{t('page.general.returnCredits')}</Text>
        <Text variant='body1'>{sectionValue}</Text>
      </SectionLayout>
      {!isFold && (
        <>
          <SectionLayout>
            <Text
              variant='body2'
              color={ColorVars.Grey['Grey 1100']}
            >{`${returnItemQty} ${returnItemLabel}`}</Text>
            <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
              {returnsValue}
            </Text>
          </SectionLayout>

          {showItemsTax && (
            <SectionLayout>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {t('v2.page.general.tax')}
              </Text>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {returnsTaxValue ? `(${returnsTaxValue})` : '-'}
              </Text>
            </SectionLayout>
          )}

          {showExtraCredit && (
            <SectionLayout>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {t('page.description.creditForExchange')}
              </Text>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {`(${toCurrency(creditAmount.amount, creditAmount?.currency)})`}
              </Text>
            </SectionLayout>
          )}
          {!isMobile && showDivider && <Divider spacing={SpacingVars['3']} />}
        </>
      )}
    </Flex>
  );
};

export default ReturnCreditsSection;
