import { assertEvent, assign } from 'xstate';

import { exchangeOrRefundSubFlow } from '../../core/machine/ExchangeOrRefundSubFlow';
import { defaultProducts, getCredit, getRecommendProducts } from '../promise';

export const mockExchangeOrRefundSubFlow = exchangeOrRefundSubFlow.provide({
  actors: {
    getCredit: getCredit,
    getRecommendProducts: getRecommendProducts,
  },
  actions: {
    toggleShowRecommendProducts: assign({
      showRecommendProducts: ({ event }) => {
        assertEvent(event, 'UPDATE_SHOW_RECOMMEND_PRODUCTS_FLAG');
        return event.data.showRecommendProducts;
      },
      products: ({ event }) => {
        assertEvent(event, 'UPDATE_SHOW_RECOMMEND_PRODUCTS_FLAG');
        if (event.data.showRecommendProducts) {
          return defaultProducts;
        } else {
          return [];
        }
      },
    }),
  },
});
