import { ReactNode, useMemo } from 'react';

import { Box, Flex, Skeleton } from '@aftership/design-system';

import { useSummaryContext } from '../SummaryProvider';
import { sectionLayoutNoremalStyle } from '../styles/summary.css';

export enum SummarySectionName {
  Total = 'total',
  CostOfReturn = 'costOfReturn',
  ShippingFee = 'shippingFee',
  ReturnCredit = 'returnCredit',
  Exchanges = 'exchanges',
  StoreCreditIncentive = 'storeCreditIncentive',
}

export interface SectionCommonProps {
  showDivider?: boolean;
}

const SectionLayout = ({
  children,
  isControlledLoading,
  name,
}: {
  children: [ReactNode, ReactNode];
  isControlledLoading?: boolean;
  name?: SummarySectionName;
}) => {
  const { isLoading } = useSummaryContext();
  const skeletonWidth = name === SummarySectionName.Total ? '141px' : '96px';

  const sectionLoading = useMemo(() => {
    // If isLoading is boolean, use it directly
    if (typeof isControlledLoading === 'boolean') {
      return isControlledLoading;
    }
    return isLoading;
  }, [isLoading, isControlledLoading]);
  return (
    <Box>
      <Flex direction='row' justifyContent='space-between'>
        {children?.[0]}
        {sectionLoading ? (
          <Skeleton className={sectionLayoutNoremalStyle} width={skeletonWidth}>
            <Skeleton.Item className={sectionLayoutNoremalStyle} />
          </Skeleton>
        ) : (
          children?.[1]
        )}
      </Flex>
    </Box>
  );
};

export default SectionLayout;
