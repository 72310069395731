import { Dispatch, SetStateAction, useCallback, useEffect, useReducer } from 'react';

import { useFlow } from 'returns-logics/react';

import { useReturnPreviewFlow } from './useReturnPreviewFlow';

import { PayStatus } from '../type';

enum PayModalName {
  PayVisible = 'payVisible',
  PayErrorVisible = 'payErrorVisible',
  NoReturnableQuantityVisible = 'noReturnableQuantityVisible',
  ExpiredVisible = 'expiredVisible',
}

enum PayModalStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

function payModalVisibleReducer(
  state: Record<PayModalName, boolean>,
  action: { name: PayModalName; status: PayModalStatus },
) {
  switch (action.status) {
    case PayModalStatus.OPEN:
      // 只打开一个，其他的关闭
      return Object.keys(state).reduce(
        (acc, key) => {
          acc[key as PayModalName] = key === action.name;
          return acc;
        },
        {} as Record<PayModalName, boolean>,
      );
    case PayModalStatus.CLOSE:
      return {
        ...state,
        [action.name]: false,
      };
    default:
      return state;
  }
}

const usePayStatusAction = ({
  status,
  returnId,
  setPayStatus,
  setSubmitting,
  stopPollingDraftReturn,
}: {
  status: PayStatus | null;
  rmaId?: string | null;
  returnId?: string | null;
  setPayStatus: Dispatch<SetStateAction<PayStatus | null>>;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  stopPollingDraftReturn: VoidFunction;
}) => {
  const { dispatch: mainDispatch } = useFlow();
  const { returnPreviewFlowDispatch, returnPreviewFlowContext } = useReturnPreviewFlow();
  // 各种支付状态的展示
  const [payVisibleStates, dispatchPayModal] = useReducer(payModalVisibleReducer, {
    [PayModalName.PayVisible]: false,
    [PayModalName.PayErrorVisible]: false,
    [PayModalName.NoReturnableQuantityVisible]: false,
    [PayModalName.ExpiredVisible]: false,
  });

  const onExit = useCallback(() => {
    mainDispatch?.({
      type: 'GO_TO_RETURN_LIST',
    });
  }, [mainDispatch]);

  const clearPayStatus = useCallback(() => {
    returnPreviewFlowDispatch?.({
      type: 'CLEAR_RETURN_ERROR',
    });
    setPayStatus(null);
  }, [setPayStatus, returnPreviewFlowDispatch]);

  useEffect(() => {
    if (status === null) {
      return;
    }
    switch (status) {
      case PayStatus.pay:
        dispatchPayModal?.({
          name: PayModalName.PayVisible,
          status: PayModalStatus.OPEN,
        });
        setSubmitting(true);
        break;
      case PayStatus.alreadyPay:
        createNewCheckout();
        break;
      case PayStatus.repeatedPay:
        dispatchPayModal?.({
          name: PayModalName.NoReturnableQuantityVisible,
          status: PayModalStatus.OPEN,
        });
        break;
      case PayStatus.paid:
        setSubmitting?.(false);
        stopPollingDraftReturn();
        break;
      case PayStatus.checkoutCanceled:
        setSubmitting?.(false);
        stopPollingDraftReturn();
        clearPayStatus();
        dispatchPayModal?.({
          name: PayModalName.ExpiredVisible,
          status: PayModalStatus.OPEN,
        });
        break;
      case PayStatus.timeout:
        clearPayStatus();
        // 返回 list 页面
        onExit();
        // TODO 支付超时是否需要 toast 提示
        break;
      case PayStatus.error:
        setSubmitting?.(false);
        stopPollingDraftReturn();
        clearPayStatus();
        dispatchPayModal?.({
          name: PayModalName.PayErrorVisible,
          status: PayModalStatus.OPEN,
        });
        break;
    }
    // 别加含有 router 相关的函数依赖，会导致循环调用
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, returnId]);

  const onAlreadyPaid = useCallback(() => {
    onExit();
  }, [onExit]);

  const createNewCheckout = useCallback(() => {
    returnPreviewFlowDispatch?.({
      type: 'CREATE_NEW_REQUEST',
    });
  }, [returnPreviewFlowDispatch]);

  const onPayCancel = useCallback(() => {
    clearPayStatus();
    setSubmitting(false);

    dispatchPayModal?.({
      name: PayModalName.PayVisible,
      status: PayModalStatus.CLOSE,
    });
    returnPreviewFlowDispatch?.({
      type: 'DELETE_DRAFT_RETURN',
      data: {
        draftId: returnPreviewFlowContext?.draftId,
      },
    });
  }, [clearPayStatus, returnPreviewFlowDispatch, setSubmitting, returnPreviewFlowContext?.draftId]);

  const onPayContinue = () => {
    returnPreviewFlowDispatch?.({
      type: 'POLLING_GET_DRAFT_RETURN',
    });
  };

  const onPayErrorModalClose = () => {
    dispatchPayModal?.({
      name: PayModalName.PayErrorVisible,
      status: PayModalStatus.CLOSE,
    });
    clearPayStatus();
    setSubmitting(false);
  };

  const onPayExpiredModalClose = () => {
    dispatchPayModal?.({
      name: PayModalName.ExpiredVisible,
      status: PayModalStatus.CLOSE,
    });
    setSubmitting(false);
  };

  const onPayNotReturnableQuantityModalClose = () => {
    dispatchPayModal?.({
      name: PayModalName.NoReturnableQuantityVisible,
      status: PayModalStatus.CLOSE,
    });
    setSubmitting(false);
  };

  return {
    payModalVisible: payVisibleStates[PayModalName.PayVisible],
    payErrorModalVisible: payVisibleStates[PayModalName.PayErrorVisible],
    noReturnableQuantityModalVisible: payVisibleStates[PayModalName.NoReturnableQuantityVisible],
    expiredModalVisible: payVisibleStates[PayModalName.ExpiredVisible],
    onAlreadyPaid,
    createNewCheckout,
    onPayCancel,
    onPayErrorModalClose,
    onPayExpiredModalClose,
    onPayNotReturnableQuantityModalClose,
    onPayContinue,
    dispatchPayModal,
  };
};

export default usePayStatusAction;
