import dynamic from 'next/dynamic';
import React, { ReactNode } from 'react';

const ProxyStyle = dynamic(() => import('./ProxyStyle').then((mod) => mod.ProxyStyle));

interface ShadowHostProps {
  children: ReactNode;
  appProxy: Maybe<Returns.AppProxyInfo>;
}

const ReturnsPageWrapper = ({ children, appProxy }: ShadowHostProps) => {
  if (!appProxy?.shopifyProxyMode) {
    return <>{children}</>;
  }

  return <ProxyStyle appProxy={appProxy}> {children}</ProxyStyle>;
};

export default ReturnsPageWrapper;
