import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { CountDown } from '@/components/CountDown';
import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

interface ShopifyInProgressModalProps {
  isOpen: boolean;
  targetDate: Date;
  onAlreadyPaid?: VoidFunction;
  onCancel?: VoidFunction;
}

export const ShopifyInProgressModal = ({
  isOpen,
  onAlreadyPaid,
  onCancel,
  targetDate,
}: ShopifyInProgressModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex direction='row' justifyContent='center'>
        <Flex
          direction='column'
          alignItems='center'
          gap={SpacingVars['2']}
          paddingX={SpacingVars['21']}
        >
          <CountDown targetDate={targetDate} />
          <Text variant='body1' textAlign={'center'}>
            {t('popup.description.processingShopifyPayment')}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button size='large' onPress={onAlreadyPaid}>
          {t('popup.request.alreadyPaid')}
        </Button>
        <Button size='large' variant='secondary' onPress={onCancel}>
          {t('popup.request.paymentCancel')}
        </Button>
      </ModalButtonGroup>
    );
  };

  if (mobile) {
    return (
      <MobilePopup isOpen={isOpen} title={t('page.error.paymentInProgress')} footer={<Footer />}>
        <Container />
      </MobilePopup>
    );
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('page.error.paymentInProgress')} suffixIcon={<></>} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};
