import React from 'react';

import { Box, Checked, Flex, Icon, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import { resolutionItemWrap } from './ResolutionItem.css';

export interface ResolutionItemProps {
  title: string;
  description?: string;
  isSelected?: boolean;
  renderChildren?: () => React.ReactNode;
}

const ReturnsPageHeaderMobile = ({
  title,
  description,
  isSelected,
  renderChildren,
}: ResolutionItemProps) => {
  return (
    <Box padding={SpacingVars['4']}>
      <Flex gap={SpacingVars['1']} direction={'column'}>
        <Flex direction={'row'} flex={1} gap={SpacingVars[4]}>
          <Box flex={1} flexBasis={0}>
            <Text
              variant={'subtitle1'}
              color={ColorVars.Grey['Grey1200']}
              className={resolutionItemWrap}
            >
              {title}
            </Text>
          </Box>
          {isSelected && <Icon source={Checked} size={SpacingVars['6']} variant='primary' />}
        </Flex>
        {renderChildren?.()}
        {description && (
          <Text
            variant={'body2'}
            color={ColorVars.Grey['Grey 1100']}
            className={resolutionItemWrap}
          >
            {description}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

const ResolutionItemDesktop = ({
  title,
  description,
  isSelected,
  renderChildren,
}: ResolutionItemProps) => {
  return (
    <Flex padding={SpacingVars['4']} flex={1} alignItems={'center'} gap={SpacingVars['5']}>
      <Flex direction={'column'} flex={1}>
        <Text
          variant={'subtitle1'}
          color={ColorVars.Grey['Grey1200']}
          className={resolutionItemWrap}
        >
          {title}
        </Text>
        {renderChildren?.()}
        {description && (
          <Text
            variant={'body2'}
            color={ColorVars.Grey['Grey 1100']}
            className={resolutionItemWrap}
          >
            {description}
          </Text>
        )}
      </Flex>
      {isSelected && (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          alignSelf={description ? 'flex-start' : 'unset'}
        >
          <Icon source={Checked} size={SpacingVars['6']} variant='primary' />
        </Flex>
      )}
    </Flex>
  );
};
const ResolutionItem = (props: ResolutionItemProps) => {
  const isMobile = useDevice().mobile;

  if (isMobile) {
    return <ReturnsPageHeaderMobile {...props} />;
  }

  return <ResolutionItemDesktop {...props} />;
};

export default ResolutionItem;
