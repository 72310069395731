import { Shops } from 'returns-logics';

import getTrackerInstance from '@/utils/tracker';

import { ShopifyFeatureTag, loadShopifyFeatures, shopifyFeaturesMap } from './shopifyUtils';

const initHotjar = async () => {
  const { hotjar } = await import('react-hotjar');
  hotjar.initialize(2399999, 6);
};

const registerConsentService = (primaryColor: string, primaryFont: string) => {
  const initCookieConsent = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.AM_CONSENT_SDK?.init?.({
      hasAuth: false,
      theme: 'automizely',
      autoBlock: false,
      custmizeBrandSettings: {
        // sdk 不支持传入空字符串,但是支持传入 undefined, 对于非法的  primaryColor ,这里容错处理传入 undefined
        primaryColor: primaryColor?.length ? primaryColor : undefined,
        primaryTextFont: primaryFont,
        secondaryTextFont: primaryFont,
      },
    });
  };

  document.readyState === 'complete'
    ? initCookieConsent()
    : window.addEventListener('load', initCookieConsent);

  // import {CONSENT_LISTENER_SRIPT} from '@aftership/cookie-banner-ui-react'
  // CONSENT_LISTENER_SRIPT add $addASConsentListener func to window
  window?.$addASConsentListener?.((value: ICookieConsentCallbackProps) => {
    if (window?.Shopify?.customerPrivacy) {
      const { setTrackingConsent } = window.Shopify.customerPrivacy;
      setTrackingConsent(!!value.analyticsConsentGranted);
    }
  });
};

type ExtraData = {
  shopInfo: Shops;
  isAppProxy: boolean;
};

const initialize3rdLibs = async (extraData: ExtraData) => {
  const { isAppProxy } = extraData;
  if (isAppProxy && window?.Shopify) {
    loadShopifyFeatures([shopifyFeaturesMap[ShopifyFeatureTag.ConsentTracking]], (error) => {
      if (error || !window.Shopify?.customerPrivacy) {
        console.error('shopify load features error', error);
        return;
      }
      const {
        customerPrivacy: { shouldShowGDPRBanner },
      } = window.Shopify;

      if (shouldShowGDPRBanner()) {
        init3rdLibsWithConsent(extraData);
      } else {
        init3rdLib(extraData);
      }
    });
  } else {
    init3rdLibsWithConsent(extraData);
  }
};

/**
 * 初始化业务埋点
 * @param orgId
 */
const initTracker = (orgId: string) => {
  getTrackerInstance().updateExtraDataByOrgId(orgId);
  // getTrackerInstance().updateExtraDataByPagetType(
  //   isAppProxy ? PageType.appProxy : PageType.shopper,
  // );
  getTrackerInstance().init();
};

export const init3rdLibsWithConsent = async (extraData: ExtraData) => {
  // @ts-ignore
  const { consentSDKTaskPush } = await import('@aftership/cookie-banner-ui-react');
  const { shopInfo } = extraData;

  const { returns_page_primary_font: primaryFont = 'Lato', theme_color: primaryColor } = shopInfo;

  registerConsentService(primaryColor, primaryFont);

  // WARNING: Please put all those 3rd party libraries that will collect user data into the consent initialization service
  // Checkout the link for more details: https://www.notion.so/automizely/Consent-SDK-9ee1dad9bf7f4afd9385c408be57ed6b
  consentSDKTaskPush({
    task: () => {
      init3rdLib(extraData);
    },
  });
};

const init3rdLib = (extraData: ExtraData) => {
  const { shopInfo } = extraData;

  initHotjar();

  if (shopInfo?.organization?.id) {
    initTracker(shopInfo?.organization.id);
  }
};

export default initialize3rdLibs;
