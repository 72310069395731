import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, CardSection, Flex, ProgressBar, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { IDraftReturn } from 'returns-logics';

import ImageList from '@/components/ImageList';
import useDevice from '@/hooks/useDevice';

import CountDown from '../../../../components/CountDown/CountDown.tsx';
import { useReturnsListSubFlow } from '../../hooks/useReturnsListSubFlow';
import PayNow from '../PayNow';

interface ReturnItemProps {
  item: IDraftReturn;
}

const DraftReturnItem = ({ item }: ReturnItemProps) => {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const { dispatch, matches } = useReturnsListSubFlow() || {};

  const isDeleteLoading = matches?.({ deleteDraftReturn: 'loading' });
  const isPullingLoading =
    matches?.('pollingGetDraftReturn') ||
    matches?.({ loadAllReturns: { getDraftReturns: 'loading' } });
  const covers = item.items.map((item) => item?.image?.src || '');

  const handleCancel = () => {
    dispatch?.({ type: 'DELETE_DRAFT_RETURN', data: { draftId: item.id } });
  };
  const handleExpiredConfirm = () => {
    dispatch?.({ type: 'RELOAD_RETURNS' });
  };
  const cancelDate = dayjs(item.created_at).add(10, 'minute').toDate();
  return (
    <Card width='100%' padding={SpacingVars['4']}>
      <CardSection>
        <Flex direction='column'>
          <Box paddingBottom={isMobile ? SpacingVars['5'] : SpacingVars['3']}>
            <ImageList list={covers} />
          </Box>
          <Text
            variant={isMobile ? 'caption2' : 'caption1'}
            paddingBottom={SpacingVars['1']}
            color={ColorVars['Grey']['Grey 1100']}
          >
            {t('v2.date_formate.create_at', {
              date: dayjs(item.created_at).format('MMM DD, YYYY'),
            })}
          </Text>
          <CountDown targetDate={cancelDate} />
          <Text variant={isMobile ? 'subtitle1' : 'title3'} color={ColorVars['Grey']['Grey1200']}>
            {t('page.error.paymentInProgress')}
          </Text>
          <Text
            variant='body2'
            color={isMobile ? ColorVars['Grey']['Grey 1100'] : ColorVars['Grey']['Grey1200']}
            paddingBottom={SpacingVars['2']}
          >
            {t('page.description.creatingReturns')}
          </Text>
          <Box paddingBottom={isMobile ? SpacingVars['5'] : SpacingVars['3']}>
            <ProgressBar fullWidth showIndeterminate value={0} maxValue={1} />
          </Box>
          <Flex gap={SpacingVars['4']}>
            <PayNow
              draftInfo={item}
              isLoading={isPullingLoading}
              isDisabled={isDeleteLoading}
              onExpiredConfirmCallback={handleExpiredConfirm}
            />
            <Button
              variant='critical'
              onPress={handleCancel}
              isLoading={isDeleteLoading}
              isDisabled={isPullingLoading}
            >
              {t('v2.returns_list.draft_cancel')}
            </Button>
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  );
};

export default DraftReturnItem;
