import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';

import { Box, Flex, Text } from '@aftership/design-system';

import { countDownContainer } from '@/components/CountDown/CountDown.css.ts';

dayjs.extend(duration);
const defaultTimeLeft = { isFinish: true, days: '00', hours: '00', minutes: '00', seconds: '00' };
const calculateTimeLeft = (targetDate: Date) => {
  const now = dayjs();
  const target = dayjs(targetDate);
  const timeDiff = target.diff(now); // 获取时间差（毫秒）

  if (timeDiff <= 0) {
    return defaultTimeLeft;
  }

  // 使用 dayjs 的 duration 计算天、小时、分钟和秒
  const remaining = dayjs.duration(timeDiff);

  const formattedDays = String(Math.floor(remaining.asDays())).padStart(2, '0');
  const formattedHours = String(remaining.hours()).padStart(2, '0');
  const formattedMinutes = String(remaining.minutes()).padStart(2, '0');
  const formattedSeconds = String(remaining.seconds()).padStart(2, '0');

  return {
    isFinish: false,
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
};

interface CountDownProps {
  targetDate: Date;
  onEnd?: VoidFunction;
}

interface CountDownBoxProps {
  countDownValue: string;
}
const CountDownBox = ({ countDownValue }: CountDownBoxProps) => {
  return (
    <Box className={countDownContainer}>
      <Text variant={'subtitle2'} textAlign={'center'}>
        {countDownValue}
      </Text>
    </Box>
  );
};

/**
 * 倒计时组件, 暂时只展示了分钟和秒
 * @param targetDate
 * @param onEnd
 * @constructor
 */
const CountDown = ({ targetDate, onEnd }: CountDownProps) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    return calculateTimeLeft(targetDate);
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      if (newTimeLeft.isFinish) {
        clearInterval(intervalId);
        onEnd?.();
      }
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(intervalId); // 清理定时器
  }, [onEnd, targetDate]);
  return (
    <Flex direction={'row'} gap={'10px'} alignItems={'center'}>
      <CountDownBox countDownValue={timeLeft.minutes} />
      <Text textAlign={'center'} variant={'subtitle2'}>
        :
      </Text>
      <CountDownBox countDownValue={timeLeft.seconds} />
    </Flex>
  );
};

export default CountDown;
