import clsx from 'clsx';
import Image from 'next/image';
import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';

import { Box, Flex, Spinner, useIsMounted } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import { useScrollUpdate } from '@/features/atta/useSrollUpdate';
import useDevice from '@/hooks/useDevice';
import { isIframe } from '@/utils/common';
import getTrackerInstance from '@/utils/tracker';

import {
  backgroundImageClassName,
  containerClassName,
  returnsPageContainer,
  returnsPageContainerInAppProxy,
  scrollbar24,
  scrollbar48,
} from './ReturnsPage.css';
import ReturnsPageFooter from './ReturnsPageFooter';
import ReturnsPageHeader from './ReturnsPageHeader';

import { PageType } from '../../../utils/tracker/consts';
import { useLayoutHidden } from '../hooks/useLayoutHidden';
import { useReturns } from '../hooks/useReturns';
import { useShopInfo } from '../hooks/useShopInfo';
import { useSyncXStateAndRoute } from '../hooks/useSyncXStateAndRoute';

interface ReturnsPageProps {
  children: ReactNode;
}

const BackgroundImage = ({ url }: { url?: string }) => {
  const heroImage = useMemo(() => {
    return url ?? require('@/assets/returns-page-background.png').default?.src;
  }, [url]);
  return (
    <Image
      fill
      sizes='100vw'
      src={heroImage}
      className={backgroundImageClassName}
      alt='returns page background'
    />
  );
};

export const Header: React.FC = () => {
  const { hiddenHeader } = useLayoutHidden();
  /**
   * @argument isCompact
   * @description 是否为紧凑模式布局，该模式下不展示 header 和 footer
   */
  const { isCompact } = useReturns() ?? {};
  const shopInfo = useShopInfo();
  return <>{!hiddenHeader && !isCompact && <ReturnsPageHeader shopInfo={shopInfo} />}</>;
};

export const Footer: React.FC = () => {
  const { hiddenFooter } = useLayoutHidden();
  const { isCompact } = useReturns() ?? {};
  const shopInfo = useShopInfo();
  return <>{!hiddenFooter && !isCompact && <ReturnsPageFooter shopInfo={shopInfo} />}</>;
};

export const Container: React.FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted();
  const { fullScreen } = useLayoutHidden();
  const { ultra, paddingTop, paddingBottom } = useDevice();

  const scrollClassName = useScrollUpdate();

  return (
    <>
      {isMounted ? (
        <Box
          flex={1}
          height={0}
          className={clsx(
            fullScreen ? undefined : `${containerClassName} ${ultra ? scrollbar48 : scrollbar24}`,
            scrollClassName,
          )}
          paddingTop={fullScreen ? undefined : paddingTop}
          paddingBottom={fullScreen ? undefined : paddingBottom}
        >
          <Flex
            direction='column'
            height={fullScreen ? '100%' : undefined}
            // https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
            margin={fullScreen ? undefined : 'auto'}
          >
            {children}
          </Flex>
        </Box>
      ) : (
        <Flex flex={1} height={0} alignItems='center' justifyContent='center'>
          <Spinner color={ColorVars['B&W']['Background']} />
        </Flex>
      )}
    </>
  );
};

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAppProxy } = useReturns() ?? {};
  const shopInfo = useShopInfo();
  const heroImage = shopInfo.hero_image?.src;

  useEffect(() => {
    // Delay to debounce to prevent push event repeatedly
    // when Page component mount and unmount quickly
    const timeoutId = setTimeout(() => {
      let pageType = PageType.shopper;

      if (isAppProxy) pageType = PageType.appProxy;
      if (isIframe()) pageType = PageType.iframe;

      getTrackerInstance().updateExtraDataByPagetType(pageType);
    }, 0);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAppProxy]);

  return (
    <>
      <Flex
        position='relative'
        direction='column'
        className={isAppProxy ? returnsPageContainerInAppProxy : returnsPageContainer}
      >
        <BackgroundImage url={heroImage} />
        {children}
      </Flex>
    </>
  );
};

const ReturnsPage = ({ children }: ReturnsPageProps) => {
  useSyncXStateAndRoute();
  return (
    <Layout>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </Layout>
  );
};

export default ReturnsPage;
