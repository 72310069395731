import getConfig from 'next/config';

import { platformHostName } from '@/constants/hostname.ts';
import { UnexpectedHostnameException } from '@/utils/errorHandler.ts';
import { logger } from '@/utils/logger.ts';
import { request } from '@/utils/request.ts';

export type PageData = {
  organization: {
    id: string;
  };
  in_white_list: boolean;
  feature_available: boolean;
  page: {
    name: string;
    available: boolean;
    enabled: boolean;
    path: string;
  };
};
const {
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();
export enum UnifiedDomainGateways {
  AsApiGateway = 'as-api-gateway',

  RcApiGateway = 'rc-api-gateway',

  AsAccounts = 'as-accounts',
  /**
   * 可能是通过 admin portal 点击 preview or create returns 打开的 returns page, 如果在unified domain 白名单, 加上 value 为 rc-admin-portal key 为 proxy_by 的 query param
   */
  RcAdminPortal = 'rc-admin-portal',
}
export const getPageDataFromPlatform = async (hostName: string) => {
  const uri = `${platformHostName}/domains/page-data?host=${hostName}&page_name=returns`;
  logger.info('getPageDataFromPlatform-uri', uri);
  let response;
  try {
    response = await request<PageData>(uri, {
      headers: { 'am-api-key': AM_API_KEY, 'Content-Type': 'application/json' },
    });
  } catch (e) {
    // 如果捕获到 UnexpectedHostnameException 则表示不在白名单内, 需要正常返回, 走不在白名单逻辑
    if (e instanceof UnexpectedHostnameException && (e.cause as any)?.status === 404) {
      return {
        organization: { id: '' },
        in_white_list: false,
        feature_available: false,
        page: { name: '', available: false, enabled: false, path: '' },
      };
    } else {
      throw e;
    }
  }

  const data = response.data;
  const isCheckPass = data?.organization?.id && data?.page?.enabled && data?.page?.available;
  logger.info('getPageDataFromPlatform-isCheckPass', isCheckPass);
  if (!data) {
    return Promise.reject(response);
  } else if (!isCheckPass) {
    // 如果 isCheckPass 为 false, 则表示返回的数据不符合预期, 需要抛出异常
    throw new UnexpectedHostnameException('Unexpected hostname');
  } else {
    return data;
  }
};
