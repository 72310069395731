import { AppContext } from 'next/app';

const indexJsonPattern = /\/_next\/data\/[^/]+\/index.json/;

export const getSafeNumber = (value?: number | string | null) => Number(value || 0);

export const isIndexJsonUpdate = (req: AppContext['ctx']['req']) => {
  return (req?.url || '').match(indexJsonPattern);
};

export const isIframe = () => {
  return self !== top;
};
