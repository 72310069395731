import { t } from 'i18next';
import React from 'react';

import { ListBox, ListBoxItem } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { Resolution, ResolutionDescription, isRefundDestination } from 'returns-logics';

import ResolutionItem from '@/features/resolution/components/ResolutionItem/ResolutionItem';
import { ResolutionSuffix, genRefundExchangeCode } from '@/i18n/dynamic';
import { toCurrency } from '@/utils/price';

import { StoreCreditIncentiveTag } from './StoreCreditIncentiveTag';

/**
 * UI 要按此排序
 */
const ResolutionSortedType = [
  Resolution.ReplaceTheSameItem,
  Resolution.StoreCredit,
  Resolution.OriginalPayment,
  Resolution.ExchangeForAnything,
  Resolution.Refundid,
];

export enum ResolutionMode {
  onlyRefund,
  hideRefundWhenHaveEfa,
}

export interface ResolutionItemData {
  type: Resolution;
  name: string;
  description: string;
  extraDescription?: React.ReactNode;
  renderChildren?: () => React.ReactNode;
}

/**
 * 翻译 resolution 的 name 和 description
 * @param defaultValue
 * @param type
 * @param suffix
 */
const translate = (defaultValue: string, type: Resolution, suffix: ResolutionSuffix) => {
  return t(
    genRefundExchangeCode({
      type,
      suffix: suffix,
    }),
    {
      rawValue: defaultValue,
      defaultValue: defaultValue,
    },
  );
};

/**
 * 排序并翻译 resolution
 * @param items
 */
const sortAndTranslate = (items: ResolutionDescription[]): ResolutionItemData[] => {
  return items
    .sort((a, b) => {
      return ResolutionSortedType.indexOf(a.type) - ResolutionSortedType.indexOf(b.type);
    })
    .map((item) => {
      return {
        type: item.type,
        name: `${translate(item.name, item.type, ResolutionSuffix.Name)} ${item.refund_estimated_total ? ` (${toCurrency(item.refund_estimated_total.amount, item.refund_estimated_total.currency)})` : ''}`,
        description: translate(item.description, item.type, ResolutionSuffix.Description),
        renderChildren:
          item.type === Resolution.StoreCredit
            ? () => <StoreCreditIncentiveTag storeCreditIncentive={item.store_credit_incentive} />
            : undefined,
      };
    });
};

export interface ResolutionListProps {
  items: ResolutionDescription[];
  selectedResolution?: Resolution;
  mode: ResolutionMode;
  onSelectedResolution: (resolution: Resolution) => void;
}

const ResolutionList = ({
  items,
  selectedResolution,
  mode,
  onSelectedResolution,
}: ResolutionListProps) => {
  let resolutionItems: ResolutionDescription[];
  const haveEfaResolution = items.some((item) => item.type === Resolution.ExchangeForAnything);
  switch (mode) {
    case ResolutionMode.onlyRefund:
      resolutionItems = items.filter((item) => isRefundDestination(item.type));
      break;
    case ResolutionMode.hideRefundWhenHaveEfa:
      resolutionItems = items.filter(
        (item) => !haveEfaResolution || !isRefundDestination(item.type),
      );
      break;
    default:
      throw new Error('Invalid mode');
  }
  const translateResolutionItems = sortAndTranslate(resolutionItems);

  const selectedKeys = selectedResolution ? [selectedResolution] : [];

  return (
    <ListBox
      rowGap={SpacingVars['4']}
      selectionMode='single'
      items={translateResolutionItems.map((item) => ({
        id: item.type,
        ...item,
      }))}
      selectedKeys={selectedKeys}
      onSelectionChange={(keys) => {
        if (typeof keys !== 'string') {
          onSelectedResolution(keys.keys().next().value as Resolution);
        }
      }}
    >
      {(item) => {
        return (
          <ListBoxItem key={item.type}>
            {({ isSelected }) => {
              return (
                <ResolutionItem
                  title={item.name}
                  description={item.description}
                  isSelected={isSelected}
                  renderChildren={item.renderChildren}
                />
              );
            }}
          </ListBoxItem>
        );
      }}
    </ListBox>
  );
};

export default ResolutionList;
