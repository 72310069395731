import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import { ShopifyInProgressModal } from '../../../InProgressModals/ShopifyInProgressModal';
import ModalButtonGroup from '../../../ModalButtonGroup/ModalButtonGroup';

export interface ShopifyPayModalProps {
  isOpen: boolean;
  checkoutUrl: string;
  onContinue: VoidFunction;
  onAlreadyPaid: VoidFunction;
  onCancel: VoidFunction;
  targetDate: Date;
}

const ShopifyPayModal = ({
  isOpen = false,
  checkoutUrl,
  onContinue,
  onAlreadyPaid,
  onCancel,
  targetDate,
}: ShopifyPayModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();
  const [progress, setProgress] = useState(false);
  const handleContinue = () => {
    window.open(checkoutUrl, '_blank');
    setProgress(true);
    onContinue && onContinue();
  };

  const handleCancel = () => {
    setProgress(false);
    onCancel?.();
  };
  if (progress) {
    return (
      <ShopifyInProgressModal
        isOpen={isOpen}
        onAlreadyPaid={onAlreadyPaid}
        onCancel={handleCancel}
        targetDate={targetDate}
      />
    );
  }

  const Container = () => {
    return (
      <Flex direction='column' justifyContent='center'>
        <Text variant='body1' textAlign={'center'}>
          {t('popup.description.checkoutWillOpenOnANewPage')}
        </Text>
        <Text variant='body1' textAlign={'center'}>
          {t('popup.description.checkoutWillCountdown', {
            maximumMinutes: 10,
          })}
        </Text>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button size='large' onPress={handleContinue}>
          {t('popup.request.continue')}
        </Button>
        <Button size='large' variant='secondary' onPress={handleCancel}>
          {t('popup.request.cancel')}
        </Button>
      </ModalButtonGroup>
    );
  };

  if (mobile) {
    return (
      <MobilePopup title={t('page.request.payAndSubmit')} isOpen={isOpen} footer={<Footer />}>
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('page.request.payAndSubmit')} suffixIcon={<></>} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default ShopifyPayModal;
