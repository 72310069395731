import { Resolution } from '../../constant';
import { AmountPrice } from '../common';

export interface ConditionalWorkflow {
  id: string;
  name: string;
  resolutions: Resolution[];
}

export enum StoreCreditIncentiveType {
  percentage = 'percentage',
  fixAmount = 'fixed_amount',
}

export interface ResolutionDescription {
  type: Resolution;
  name: string;
  description: string;
  store_credit_incentive: AmountPrice | null; // incentive 的值，没命中就为 null
  refund_estimated_total: AmountPrice | null; // 前端展示预计 refund 价格
}

export interface ResolutionMatchingData {
  applied_resolutions: Resolution[];
  resolutions: ResolutionDescription[];
  show_instant_exchange_toggle: boolean;
}

export interface ReturnItemsPayload {
  item_id: string;
  quantity: number;
  reason: string;
  subreason: string;
}
export interface GetMatchingResolutionsInput {
  input: {
    token: string;
    payload: { returnItems: Array<ReturnItemsPayload>; isGiftReturn: boolean };
  };
}
