export enum Resolution {
  ReplaceTheSameItem = 'exchange',
  StoreCredit = 'store_credit',
  OriginalPayment = 'original_payment',
  ExchangeForAnything = 'exchange_for_anything',
  /** @deprecated */
  Refundid = 'refundid',
}
export enum RefundDestination {
  StoreCredit = 'store_credit',
  OriginalPayment = 'original_payment',
  /** @deprecated */
  Refundid = 'refundid',
}

export const RefundResolutionOrder = [
  Resolution.StoreCredit,
  Resolution.OriginalPayment,
  Resolution.Refundid,
];
