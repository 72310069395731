import { ErrorActorEvent, assign, enqueueActions, sendParent, setup } from 'xstate';

import { DefaultPrice } from './const.ts';
import {
  ExchangeOrRefundSubFlowContext,
  ExchangeOrRefundSubFlowEvent,
  ExchangeOrRefundSubFlowInput,
} from './types.ts';

import { ItemResolution, Resolution } from '../../constant';
import { getRecommendProducts } from '../../promise/products.ts';
import { postReturnPreviewActor } from '../../promise/review.ts';
import { ErrorResponse } from '../../request/ErrorResponse.ts';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils.ts';
import { decisionMethodOrReviewActor } from '../DecisionMethodOrReview';
import {
  ResolutionModalChooseProductEvent,
  ResolutionModalChooseRefundEvent,
  ResolutionModalShopNowEvent,
} from '../ResolutionSubFlow/types.ts';

export const exchangeOrRefundSubFlow = setup({
  types: {
    input: {} as ExchangeOrRefundSubFlowInput,
    context: {} as ExchangeOrRefundSubFlowContext,
    events: {} as ExchangeOrRefundSubFlowEvent,
  },
  actors: {
    getCredit: postReturnPreviewActor,
    decisionMethodOrReviewActor,
    getRecommendProducts,
  },
  actions: {
    toggleShowRecommendProducts: () => {},
  },
}).createMachine({
  context: ({ input }) => ({
    ...input,
    priceInfo: {
      selectedItemsWithCreditAmount: { ...DefaultPrice },
      selectedItemsAmount: { ...DefaultPrice },
      preDiscountCreditAmount: { ...DefaultPrice },
      extraCreditAmount: { ...DefaultPrice },
    },
    products: [],
  }),
  initial: 'fetchCreditAndRecommendProducts',
  states: {
    fetchCreditAndRecommendProducts: {
      id: 'fetchCreditAndRecommendProducts',
      type: 'parallel',
      states: {
        fetchCredit: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getCredit',
                input: ({ context }) => {
                  return {
                    token: context.token,
                    payload: {
                      order: {
                        id: context.orderId,
                      },
                      return_items: context.selectedItems?.map((selectedItem) => ({
                        item_id: selectedItem.itemId,
                        quantity: selectedItem.quantity,
                        resolution: ItemResolution.Refund,
                        reason: selectedItem.selectedReason,
                        subreason: selectedItem.selectedSubReason,
                      })),
                      resolution: Resolution.ExchangeForAnything,
                    },
                  };
                },
                onDone: {
                  target: 'success',
                  actions: [
                    assign({
                      priceInfo: ({ event }) => {
                        const {
                          credit_total_set,
                          refund_credit_set,
                          pre_discount_credit_set,
                          extra_credit_set,
                        } = event.output?.summary ?? {};
                        return {
                          selectedItemsWithCreditAmount: credit_total_set?.presentment_money,
                          selectedItemsAmount: refund_credit_set?.presentment_money,
                          preDiscountCreditAmount: pre_discount_credit_set?.presentment_money,
                          extraCreditAmount: extra_credit_set?.presentment_money,
                        };
                      },
                    }),
                  ],
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getCredit error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: {
              type: 'final',
            },
          },
        },
        fetchRecommendProducts: {
          initial: 'loading',
          states: {
            decision: {
              always: [
                {
                  guard: ({ context }) => context.showRecommendProducts,
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getRecommendProducts',
                input: ({ context }) => {
                  return {
                    token: context.token,
                    returnItemProductIds: context.selectedItems.map((item) => item.productId),
                  };
                },
                onDone: {
                  target: 'success',
                  actions: [assign({ products: ({ event }) => event.output ?? [] })],
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('fetchRecommendProducts error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: { type: 'final' },
          },
        },
      },
      onDone: {
        target: 'waitingForSelect',
      },
    },
    waitingForSelect: {
      on: {
        SELECT_PRODUCT: {
          actions: sendParent(
            ({ event }): ResolutionModalChooseProductEvent => ({
              type: 'CHOOSE_PRODUCT',
              data: {
                productId: event.data.productId,
                productUrl: event.data.productUrl,
              },
            }),
          ),
        },
        SELECT_SHOP_NOW: {
          actions: [
            sendParent(
              (): ResolutionModalShopNowEvent => ({
                type: 'CHOOSE_SHOP_NOW',
              }),
            ),
          ],
        },
        SELECT_REFUND_ME: {
          actions: [
            sendParent(
              (): ResolutionModalChooseRefundEvent => ({
                type: 'CHOOSE_REFUND',
              }),
            ),
          ],
        },
      },
    },
  },
  on: {
    SELECT_CANCEL: {
      actions: [
        sendParent({
          type: 'CANCEL_CHOOSE_EFA_OR_REFUND',
        }),
      ],
    },
    /* preview */
    UPDATE_SHOW_RECOMMEND_PRODUCTS_FLAG: {
      actions: 'toggleShowRecommendProducts',
    },
  },
});
